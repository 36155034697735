import React from 'react';

import { JobApplyForm } from '../../components/job-apply-form-modal';
import SEO from '../../components/seo';
import styles from './job.module.css';

// import jobBgSrc from "../../images/job-bg.png"
const JobPage = () => {
  return (
    <>
      <SEO title="Performance Marketing Specialist" />

      <div className={styles.bgJob}>
        <div className={styles.job}>
          <h1 className={styles.textHeader}>Performance Marketing Specialist</h1>

          <p className={styles.text}>
            NutritionPro – jsme úspěšný a rostoucí startup z oblasti FoodTech. Vyvinuli jsme algoritmus, který na základě tvých tělesných údajů navrhne jídelníček na míru. A to je teprve začátek – přesně spočítaná jídla pro tebe taky uvaříme a dovezeme!
          </p>

          <h3 className={styles.textSubHeader}>Co u nás budeš dělat?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Příprava a řízení výkonnostních kampaní pro CZ a DE trhy (návrh konceptu, příprava designu společně s naším designerem, nastavení kampaní) pro různé placené kanály (Facebook / IG / Youtube a další)
            </li>
            <li className={styles.newList}>
              Příprava marketingových podkladů
            </li>
            <li className={styles.newList}>
              Monitorování, analyzování a reportování výkonnosti marketingu na základě hlavních KPIs (CPC, CPL, CPD) a následná interpretace včetně návrhů na zlepšování
            </li>
            <li className={styles.newList}>
              Podílet se v influence marketing a B2B marketing
            </li>
            <li className={styles.newList}>
              Tvorba e-mailu a newsletteru (příprava konceptu, copywriting, samotné nastavení)
            </li>
            <li className={styles.newList}>
              Účast na akvizici nových marketingových kanálů
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co od tebe očekáváme?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Alespoň 3 roky pracovních zkušeností v oblasti performance marketingu, správy a optimalizace kampaní Facebook a jiných performance kanálů
            </li>
            <li className={styles.newList}>
              Zkušenost s SEM je výhodou
            </li>
            <li className={styles.newList}>
              Kreativita
            </li>
            <li className={styles.newList}>
              Schopnost rychle se učit a pracovat samostatně
            </li>
            <li className={styles.newList}>
              Výborné analytické dovednosti a schopnost je využít při vyvozování závěrů a navrhování zlepšení
            </li>
            <li className={styles.newList}>
              Proaktivnost, zodpovědnost, pečlivost a samostatný přístup ke svěřeným úkolům
            </li>
            <li className={styles.newList}>
              Výborná znalost češtiny a pokročilá znalost angličtiny min. B2
            </li>
            <li className={styles.newList}>
              Vynikající znalost Google Sheets a MS Excelu
            </li>
            <li className={styles.newList}>
              Schopnost řešit problémy a dotahovat věci do konce
            </li>
            <li className={styles.newList}>
              Organizační a komunikační dovednosti
            </li>
            <li className={styles.newList}>
              Proaktivní přístup, zodpovědnost, spolehlivost, loajalita
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              spolupráce s ambiciózním tymem nadšenců
            </li>
            <li className={styles.newList}>
              motivační finanční ohodnocení a měsíční bonus
            </li>
            <li className={styles.newList}>
              plně hrazené vzdělávací kurzy a konzultace s předními odborníky v oboru
            </li>
            <li className={styles.newList}>
              firemní obědy zdarma a multisport kartu
            </li>
          </ul>

          <p className={styles.text}>
            A když už jsme u místa tvého působiště, sídlíme v Karlíně. Takže tě čeká super prostředí pro networking, žádný dress code a pražská panoramata z naší úžasné terasy.
          </p>

          <h3 className={styles.textSubHeader}>
            Těšíme se na tvoje CV a rádi tě uvítáme v našem týmu NutritionPro!
          </h3>
          <h3 className={styles.textSubHeader}>Kontakty:</h3>
          <a className={styles.jobsEmail} href="mailto:ana@nutritionpro.cz">
           jobs@nutritionpro.cz
          </a>
          {/* <a className={styles.jobsEmail} href="tel:+420608068463">
            +420 608 068 463
          </a> */}
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_marketingManager" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JobPage
